<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1 login-signin-on
        d-flex
        flex-column flex-lg-row flex-row-fluid
        bg-white
      "
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto">
        <!-- <div class="d-flex flex-column-auto flex-column pt-lg-30 pt-10">
          <a href="#" class="text-center mb-15">
            <img src="media/logos/ddv.png" alt="" class="max-h-80px" />
          </a>
          <h2
            class="
              font-weight-bolder
              text-center
              font-size-h3 font-size-h1-lg
              text-white
            "
          >
            D-POS Bán Hàng
          </h2>
        </div> -->
        <div
          class="
            d-none
            d-lg-flex
            aside-img
            flex-row-fluid
            bgi-no-repeat bgi-position-y-center bgi-position-x-center
            mb-10
          "
          style="background-image: url('media/bg/bg-login.png')"
        ></div>
      </div>

      <!--begin::Content-->
      <div
        class="
          flex-row-fluid
          d-flex
          flex-column
          position-relative
          p-7
          overflow-hidden
        "
      >
        <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
          <router-view></router-view>
        </div>
        <span class="text-right"
          ><b>Version {{ version }}</b></span
        >
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import { mapState } from 'vuex';
import { getSetting } from '@/core/config/server/';
export default {
  name: 'auth',
  methods: {},
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    version() {
      return getSetting().version;
    },
  },
};
</script>
